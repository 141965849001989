@media (max-width: 992px) {
  .no-padding-xs-sm {
    padding: 0;
  }

  .main-container {
    padding-top: 20px;
  }

  .navbar {
    border-radius: 0;
    margin-bottom: 10px;
  }

  header {
    height: 85px;

    .container {
      padding: 0;
    }

    div {
      height: auto;
      display: block;

      .well {
        margin: 0;
        padding: 0;
        text-align: center;
      }

      .logo {
        max-height: 80px;
        margin-bottom: 20px;
      }

      &.row {
        margin: 0;
      }
    }
  }

  nav .container {
    padding: 0;
  }

  .navbar-inverse .navbar-brand {
    color: black;
    font-family: 'PT Sans', sans-serif;
  }

  .property-row div {
    border: 1px solid black;
  }

  .property-row .property {
    border: 1px solid black;
  }
}